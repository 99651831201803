<template>
	<div id="navbar">
		<div class="nav-left">
			<div class="nav-logo">
				<a href="/" v-smooth-scroll="{ duration: 1500, offset: 150 }">
					<img class="nav-logo-image" v-bind:src="'./images/logos/muskle_logo_sm.png'" />
				</a>
			</div>
		</div>
		<div class="nav-right">
			<div class="nav-items" v-if="homepageBool === 'true'">
				<div class="nav-item">
					<a href="#about" v-smooth-scroll="{ duration: 1500, offset: -150 }">About</a>
				</div>
				<div class="nav-item">
					<a href="#pricing" v-smooth-scroll="{ duration: 1500, offset: -150 }">Pricing</a>
				</div>
				<div class="nav-item">
					<a href="#contact" v-smooth-scroll="{ duration: 1500, offset: -150 }">Contact</a>
				</div>
				<div class="nav-item">
					<a href="01-aug-2018">Newsletter</a>
				</div>
			</div>
			<div class="nav-items" v-else-if="homepageBool === 'false'">
				<div class="nav-item">
					<a href="/#about" v-smooth-scroll="{ duration: 1500, offset: -150 }">About</a>
				</div>
				<div class="nav-item">
					<a href="/#pricing" v-smooth-scroll="{ duration: 1500, offset: -150 }">Pricing</a>
				</div>
				<div class="nav-item">
					<a href="/#contact" v-smooth-scroll="{ duration: 1500, offset: -150 }">Contact</a>
				</div>
				<div class="nav-item">
					<a href="01-aug-2018">Newsletter</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		props: {
			homepage: ''
        },

		data () {
			return {
				homepageBool: this.homepage
			}
        }
    }
</script>

<style>
	#navbar {
		position: fixed;
		display: flex;
		top: 0;
		height: 100px;
		width: 100%;
		background-color: #000;
		padding: 25px 0;
		z-index: 10;
	}
	.nav-left {
		display: flex;
		flex-grow: 1;
		align-items: center;
		padding-left: 5%;
	}
	.nav-logo {
		display: flex;
		align-items: center;
		min-width: 0;
	}
	.nav-logo-image {
		width: 250px;
		max-width: 100%;
		max-height: 100%;
	}
	.nav-right {
		display: flex;
		flex-grow: 1;
		align-items: center;
	}
	.nav-items {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		align-items: baseline;
		flex-direction: row;
		font-family: 'Raleway';
		margin-right: 30px;
	}
	.nav-item {
		flex-grow: 1;
		color: #fff;
		padding-left: 5%;
		padding-right: 5%;
		font-size: 20px;
	}
	a {
		text-decoration: none;
		color: #fff;
	}
	@media only screen and (max-device-width : 750px) {
		.nav-item {
			font-size: 14px;
		}
	}
	.nav-item:hover {
		cursor: pointer;
	}
	@media only screen and (device-width : 375px) {
		#navbar {
			position: fixed;
			display: flex;
			top: 0;
			height: 100px;
			width: 100%;
			background-color: #000;
			padding: 5px 0;
			z-index: 10;
		}
		.nav-left {
			display: flex;
			flex-grow: 1;
			align-items: center;
			padding-left: 5%;
		}
		.nav-logo {
			display: flex;
			align-items: center;
			min-width: 0;
		}
		.nav-logo-image {
			width: 100px;
			max-width: 100%;
			max-height: 100%;
		}
		.nav-right {
			display: flex;
			flex-grow: 1;
			align-items: center;
		}
		.nav-items {
			display: flex;
			flex-grow: 1;
			justify-content: center;
			align-items: baseline;
			flex-direction: row;
			font-family: 'Raleway';
			margin-right: 5px;
		}
		.nav-item {
			flex-grow: 1;
			color: #fff;
			padding-left: 1%;
			padding-right: 1%;
			font-size: 14px;
		}
	}
</style>