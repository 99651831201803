<template>
  <div id="app">
    <navbar homepage="true"></navbar>
    <banner-image imagelink="guy_doing_pushups_in_a_field.jpeg"></banner-image>
    <inline-content-block id="about"></inline-content-block>
    <content-block id="pricing"></content-block>
    <contact-form></contact-form>
  </div>
</template>

<script>
import BannerImage from '../components/BannerImage.vue'
import ContactForm from '../components/ContactForm.vue'
import ContentBlock from '../components/ContentBlock.vue'
import InlineContentBlock from '../components/InlineContentBlock.vue'
import Navbar from '../components/NavBar.vue'

export default {
  name: 'app',
  components: {
    BannerImage,
    ContactForm,
    ContentBlock,
    InlineContentBlock,
    Navbar
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}
h2 {
    font-family: 'Raleway';
    margin-bottom: 25px;
}
p {
    font-family: 'Raleway';
    font-size: 20px;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
