import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/01-jan-2018',
      name: 'january-newsletter',
      component: () => import(/* webpackChunkName: "january-newsletter" */ './views/JanuaryNewsletter.vue')
    },
    {
      path: '/01-feb-2018',
      name: 'february-newsletter',
      component: () => import(/* webpackChunkName: "february-newsletter" */ './views/FebruaryNewsletter.vue')
    },
    {
      path: '/01-mar-2018',
      name: 'march-newsletter',
      component: () => import(/* webpackChunkName: "march-newsletter" */ './views/MarchNewsletter.vue')
    },
    {
      path: '/01-apr-2018',
      name: 'april-newsletter',
      component: () => import(/* webpackChunkName: "april-newsletter" */ './views/AprilNewsletter.vue')
    },
    {
      path: '/01-may-2018',
      name: 'may-newsletter',
      component: () => import(/* webpackChunkName: "may-newsletter" */ './views/MayNewsletter.vue')
    },
    {
      path: '/01-jun-2018',
      name: 'june-newsletter',
      component: () => import(/* webpackChunkName: "june-newsletter" */ './views/JuneNewsletter.vue')
    },
    {
      path: '/01-jul-2018',
      name: 'july-newsletter',
      component: () => import(/* webpackChunkName: "july-newsletter" */ './views/JulyNewsletter.vue')
    },
    {
      path: '/01-aug-2018',
      name: 'august-newsletter',
      component: () => import(/* webpackChunkName: "august-newsletter" */ './views/AugustNewsletter.vue')
    },
    {
      path: '/01-sep-2018',
      name: 'september-newsletter',
      component: () => import(/* webpackChunkName: "september-newsletter" */ './views/SeptemberNewsletter.vue')
    },
  ]
})
