<template>
	<div class="banner-image">
		<p class="banner-caption">Unlock your potential</p>
		<img v-bind:src="this.imageLinkUrl" class="banner-image-image">
	</div>
</template>

<script>
    export default {
        props: {
        	imagelink: ''
        },

        data () {
        	return {
        		imageLinkUrl: 'images/banners/' + this.imagelink
        	}
        },

        mounted() {
        }
    }
</script>

<style>
	.banner-image {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		position: relative;
		min-width: 0;
		max-height: calc(100vh - 100px);
		overflow: hidden;
		/*margin-top: 100px;*/
	}
	.banner-caption {
		z-index: 5;
		font-size: 30px;
		font-family: 'Raleway';
		font-weight: 900; 
		color: #000;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%);
		background-color: rgba(255, 255, 255, 0.5);
		padding: 50px;
	}
	.banner-image-image {
		flex: 1;
		width: 100vw;
	}
	
	@media only screen and (device-width : 375px) {
		.banner-image {

		}
		.banner-caption {
			font-size: 24px;
			width: 70%;
			padding: 30px;
			top: 40%;
		}
		.banner-image-image {

		}
	}
</style>