<template>
    <div class="inline-content-block">
        <div class="content">
            <div class="content-left">
                <img class="trainer-image" src="images/photos/meet_jamie.jpg">
            </div>
            <div class="content-right">
                <h2>Jamie Murphy</h2>
                <p>
                    Fitness Coach and owner, Jamie has a passion for movement.
                </p>
                <p>
                    Committed to ensuring form is spot on, Jamie emphasises on the body being an amazing machine which is capable of great strength, stamina and suppleness.
                </p>
                <p>
                    Results driven and fun-filled sessions mean the most for the client and encourage a love for fitness.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            
        },

        data () {
            return {
                
            }
        },

        mounted() {
            
        }
    }
</script>

<style>
    .inline-content-block {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content {
        display: flex;
        flex-direction: row;
        padding: 75px 50px 75px;
    }
    .content-left {
        width: 50%;
        flex-grow: 1;
        text-align: center;
    }
    .trainer-image {
        width: 100%;
        max-width: 300px;
    }
    .content-right {
        width: 50%;
        flex-grow: 1;
        padding: 25px;
        text-align: center;
    }
    h2 {
        font-family: 'Raleway';
        margin-bottom: 25px;
    }
    p {
        font-family: 'Raleway';
        font-size: 20px;
    }

    @media only screen and (device-width : 375px) {
        .content {
            display: flex;
            flex-direction: column-reverse;
            padding: 10px 0;
        }
        .content-left {
            width: 100%;
            flex-grow: 1;
            text-align: center;
        }
        .trainer-image {
            width: 75%;
            max-width: 300px;
        }
        .content-right {
            width: 90%;
            flex-grow: 1;
            text-align: center;
        }
        h2 {
            font-family: 'Raleway';
            margin-bottom: 25px;
        }
        p {
            font-family: 'Raleway';
            font-size: 20px;
        }
    }
</style>