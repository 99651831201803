<template>
    <div id="contact" class="contact">
        <h2>Contact Us</h2>
        <div id="contact-form" v-if="!contactFormSent">
            <label for="contact-name">Name:</label>
            <input v-model="name" type="text" id="contact-name" name="name" required />
            <label for="contact-email">Email:</label>
            <input v-model="email" type="email" id="contact-email" name="email" required />
            <label for="contact-phone">Phone:</label>
            <input v-model="phone" type="text" id="contact-phone" name="phone" required />
            <label for="contact-enquiry">Enquiry:</label>
            <textarea v-model="enquiry" id="contact-enquiry" name="enquiry"></textarea>
            <button @click="submitContactForm()" type="submit">
                Send Form
            </button>
        </div>
        <div id="contact-form" v-if="contactFormSent">
            <p>
                Thank you for your enquiry! We will be in touch shortly.
            </p>
        </div>
    </div>
</template>

<script>
    import firebase from 'firebase'
    var config = {
        apiKey: "AIzaSyCnWBAui0o0o-u4tRtDynEsxGsEIA3FBiU",
        authDomain: "muskle-website.firebaseapp.com",
        databaseURL: "https://muskle-website.firebaseio.com",
        projectId: "muskle-website",
        storageBucket: "muskle-website.appspot.com",
        messagingSenderId: "425335795302"
    };
    firebase.initializeApp(config);
    var database = firebase.database();

    export default {
        data: function () {
            return {
                contactFormSent: false,
                name: '',
                email: '',
                phone: '',
                enquiry: ''
            }
        },

        methods: {
            submitContactForm: function () {
                database.ref(Date.now() + '-' + Math.floor(Math.random() * 1000000000)).set({
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    enquiry: this.enquiry
                });
                this.contactFormSent = true
            }
        }
    }
</script>

<style>
    .contact {
        padding: 75px 0;
    }
    .contact h2 {
        padding-bottom: 25px;
        text-align: center;
    }
    label {
        align-self: flex-start;
        padding-top: 10px;
        padding-bottom: 2px;
        padding-left: 25%;
        font-size: 15px;
        font-family: 'Raleway';
        font-weight: 400;
    }
    #contact-form {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    #contact-form input {
        width: 50%;
        padding: 5px;
        font-size: 14px;
        border: 2px solid #ddd;
    }
    #contact-form textarea {
        width: 50%;
        padding: 5px;
        font-size: 14px;
        border: 2px solid #ddd;
        resize: none;
    }
    #contact-form button {
        width: calc(50% + 15px);
        padding: 15px;
        margin-top: 10px;
        font-size: 16px;
        font-family: 'Raleway';        
        border: 2px solid #ddd;
        background-color: #FFF;
        /* Firefox */
        -moz-transition: background-color 0.5s ease-in;
        /* WebKit */
        -webkit-transition: background-color 0.5s ease-in;
        /* Opera */
        -o-transition: background-color 0.5s ease-in;
        /* Standard */
        transition: background-color 0.5s ease-in;
    }
    #contact-form button:hover {
        cursor: pointer;
        background-color: #eee;
        /* Firefox */
        -moz-transition: all 0.5s ease-in-out;
        /* WebKit */
        -webkit-transition: all 0.5s ease-in-out;
        /* Opera */
        -o-transition: all 0.5s ease-in-out;
        /* Standard */
        transition: all 0.5s ease-in-out;
    }
</style>