<template>
	<div class="content-container">
		<div class="section-header">
			<h2>
				Pricing
			</h2>
		</div>
		<div class="pricing-options">
			<div class="pricing-option">
				<h4>
					Outdoor Bootcamp
				</h4>
				<p>
					$25/session
				</p>
			</div>
		</div>
		<h3>
			Bootcamp Packages
		</h3>
		<div class="pricing-options">
			<div class="pricing-option">
				<h4>
					10 sessions for $200
				</h4>
				<p>
					$20/session
				</p>
			</div>
			<div class="pricing-option">
				<h4>
					20 sessions for $300
				</h4>
				<p>
					$15/session
				</p>
			</div>
			<div class="pricing-option">
				<h4>
					36 sessions for $450
				</h4>
				<p>
					$12.50/session
				</p>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        props: {
        	
        },

        data () {
        	return {
        		
        	}
        },

        mounted() {
            
        }
    }
</script>

<style>
	.content-container {
		display: flex;
		flex-direction: column;
		padding-top: 50px;
		padding-bottom: 75px;
		background-color: #f2f2f2;
	}
	h2 {
		text-align: center;
	}
	.pricing-options {
		display: flex;
		flex-direction: row;
		padding: 25px;
	}
	.pricing-option {
		padding: 0 10px 45px 10px;
		text-align: center;
		flex-grow: 1;
		border-left: 1px solid #d8d8d8;
	}
	.pricing-option:first-child {
		border-left: none;
	}
	h3 {
		padding-bottom: 10px;
		text-align: center;
	}
	h4 {
		padding-bottom: 20px;
	}

	@media only screen and (device-width : 375px) {
		.pricing-options {
			display: flex;
			flex-direction: row;
			padding: 12.5px;
		}
		.pricing-option {
			padding: 0 5px 22.5px 5px;
			text-align: center;
			flex-grow: 1;
			border-left: 1px solid #d8d8d8;
		}
		.pricing-option:first-child {
			border-left: none;
		}
		h3 {
			padding-bottom: 5px;
			text-align: center;
		}
		h4 {
			padding-bottom: 10px;
		}
		p {
			font-size: 14px;
		}
	}
</style>